import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
 
const orders = [
  {
    id: "MPMME12822455",
    status: "Pickup Cancelled",
    date: "Tue, 9 Jul'24",
    image: "https://m.media-amazon.com/images/I/71i2XhHU3pL._AC_UF1000,1000_QL80_.jpg", 
    name: "OPPO F21s Pro 5G",
    specs: "8 GB/128 GB",
    amount: 9551,
    statusColor: "error",
    address : "chamudeshwari #12366,Vijayanagar 4th stage,Near arch,Mysore,Karnataka,570017",
    pickupDate : "3 Feb, 2025",
    pickupTime : "10:00 AM - 02:00PM"
  },
  {
    id: "MPMCA12777462",
    status: "Pickup Completed",
    date: "Fri, 5 Jul'24",
    image: "https://m.media-amazon.com/images/I/71i2XhHU3pL._AC_UF1000,1000_QL80_.jpg", // Replace with actual image URL
    name: "Apple iPhone 8",
    specs: "2 GB/256 GB",
    amount: 7461,
    statusColor: "success",
    address : "chamudeshwari #12366,Vijayanagar 4th stage,Near arch,Mysore,Karnataka,570017",
   pickupDate : "18 Feb, 2025",
    pickupTime : "08:00 AM - 05:00PM"
  },
];

const MyOrders = () => {
    const navigate = useNavigate();
   
 

  return (
    <Box sx={{ p: 2, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
        
      <Typography variant="h6" fontWeight="bold" mb={2}>
      <Button startIcon={<ArrowBackIcon  onClick={() => navigate("/")} />}></Button>
      Orders List
      </Typography>

      {orders.map((order, index) => (
        <Card key={index} sx={{ mb: 2, borderRadius: 2, p: 2 }} onClick={() => navigate("/order-details", { state: { order} })}>
          <Box display="flex" alignItems="center" mb={1}>
            <IconButton color={order.statusColor}>
              {order.statusColor === "error" ? <CancelIcon /> : <CheckCircleIcon />}
            </IconButton>
            <Box ml={1}>
              <Typography fontWeight="bold">{order.status}</Typography>
              <Typography variant="body2" color="textSecondary">
                {order.date}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <CardMedia
              component="img"
              sx={{ width: 60, height: 60, borderRadius: 1 }}
              image={order.image}
              alt={order.name}
            />
            <CardContent sx={{ flexGrow: 1, pl: 2 }}>
              <Typography variant="caption" color="textSecondary">
                Order ID: {order.id}
              </Typography>
              <Typography fontWeight="bold">{order.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {order.specs}
              </Typography>
            </CardContent>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="bold">
              Sell Amount
            </Typography>
            <Typography fontWeight="bold">{order.amount}</Typography>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default MyOrders;
