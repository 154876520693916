import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Grid
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const OrderDetails = () => {
  const steps = ["Pickup Requested", "On the Way","Pickup Started","Pickup Completed"];
  const location = useLocation();
  const navigate = useNavigate();
  const order = location.state?.order;
   // Determine step color
  const getStepColor = (index) => {
    if (index === 0) return "green";
    if (index === steps.length - 1 && order.status === steps.length - 1) {
      return "green";
    }
    return order.status >= index ? "green" : "red";
  };
 
  return (
    <>
    <Box sx={{ p: 1, backgroundColor: "#ffff", minHeight: "100vh" }}>
      {/* Back Button */}
      <Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/my-orders")}>
        Back
      </Button>

      {/* Order Header */}
      <Typography variant="h6" fontWeight="bold" mt={2}>
        Device Details
      </Typography>

      {/* Order Card */}
      <Card sx={{ p: 2, borderRadius: 2 }}>
        <Box display="flex" mt={2} alignItems="center" style={{gap:"10%"}}>
          <img
            src={order.image}
            alt={order.name}
            width={80}
            height={80}
            style={{ borderRadius: 8 }}
          />
          <CardContent sx={{ flexGrow: 1, pl: 2 }}>
            <Typography variant="body2" fontWeight="bold">{order.name}</Typography>
            <Typography variant="body2" color="textSecondary">Service Number: {order.id}</Typography>
            <Typography variant="body2" color="textSecondary">Order created on: {order.date}</Typography>
            <Typography fontWeight="bold" color="error.main" mt={1}>  {order.amount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.amount)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.amount > 0 ? order.amount : order.amount)}`}</Typography>
          </CardContent>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Order Status with Stepper */}
        <Typography fontWeight="bold" mb={1}>Order Status</Typography>
        <Stepper activeStep={order.status} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel sx={{ color: getStepColor(index) }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Divider sx={{ my: 2 }} />

        {/* Pickup Address */}
        <Typography fontWeight="bold">Pickup Address</Typography>
        <Typography variant="body2" color="textSecondary">{order.address}</Typography>

        <Divider sx={{ my: 2 }} />
        {/* Pickup Date and Time */}
        <Typography fontWeight="bold">Pickup Date & Time</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <CalendarTodayIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{order.pickupDate}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <AccessTimeIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{order.pickupTime}</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />
     
    
    
      </Card>
    </Box>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ p: 2, backgroundColor: "#fff", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
  <Typography variant="h6" sx={{ fontWeight: "bold" }}>Price Summary</Typography>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Base Price:</Typography>
    <Typography variant="body1" fontWeight="bold">
      {order.amount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.amount)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.amount > 0 ? order.amount : order.amount)}`}
    </Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Pickup Charges:</Typography>
    <Typography variant="body1">
      Free <span style={{ textDecoration: "line-through" }}>₹ 100</span>
    </Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Processing Fee:</Typography>
    <Typography variant="body1">₹0</Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Total Amount:</Typography>
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
      {order.amount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.amount)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.amount > 0 ? order.amount : order.amount)}`}
    </Typography>
  </Grid>
</Box>

  <Box display="flex" justifyContent="space-between" mt={2}>
          <Button 
            variant="outlined" 
            color="error" 
            sx={{ borderRadius: 2, fontWeight: "bold", px: 3 }}
          >
            Cancel Order
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ borderRadius: 2, fontWeight: "bold", px: 3 }}
          >
            Reschedule
          </Button>
        </Box>
  </>
  );
};

export default OrderDetails;
