import React, { useState,useEffect  } from 'react';
import { Box, Typography, Grid, Radio, RadioGroup, FormControlLabel, Button, Alert, useMediaQuery, Container,  Snackbar,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Screen from './Screen';
import ScreenDefects from './ScreenDefects';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';
import Variant from './Variant';
import MuiAlert from '@mui/material/Alert';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import { useDispatch, useSelector } from 'react-redux';
import { handleFixedPrice } from '../../redux/WrapperSlice';
import {handleAnswers} from "../../redux/WrapperSlice";
const Device = ({userLogDetails,onFormData,onDeviceDetails}) => {
 
  const [answers, setAnswers] = useState({
    calls: '',
    touch: '',
    screenOriginal: '',
    warranty: '',
  });
  const rowDetails = useSelector((state) => state.wrapper.rowDetails);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const[ShowScreenDefects,setShowScreenDefects] = useState(false);
  const [showDevice, setShowDevice] = useState(true); // Initially show the device section
  const [currentValue, setCurrentValue] = useState(rowDetails.MaxValue);
  const [FixedPrice,setFixedPrice] = useState(0);
  const [warrantyStatus, setWarrantyStatus] = useState(false);
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const city = useSelector((state) => state.wrapper.city);
  const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);


  const isMobile = useMediaQuery('(max-width:600px)');
 

  const handleChange = (event) => {
    const { name, value } = event.target;
   setAnswers((prev) => ({
      ...prev,
      [name]: value,
    }));
    dispatch(handleAnswers({ [name]: value }));
       

  };
  const calculateDeductions = () => {
    let maxValue = rowDetails.MaxValue; 
  


    // Calculate deduction based on 'calls'
    if (answers.calls === 'no') {
      if (maxValue < 15000) {
        setFixedPrice(1200)
         dispatch(handleFixedPrice(1200))
        }
      else if (maxValue >= 15000 && maxValue <= 35000) {
        setFixedPrice(1700);
        dispatch(handleFixedPrice(1700))
      }
      else if (maxValue > 35000 && maxValue <= 50000) {
        setFixedPrice(2200);
        dispatch(handleFixedPrice(2200))

      
      }
      else if (maxValue > 50000) {
        setFixedPrice(3000);
        dispatch(handleFixedPrice(3000))

      }
    }

   setCurrentValue(maxValue);

 
   setWarrantyStatus(answers.warranty === 'yes' ? true : false);
   
   
  };

  const handleContinue = () => {
    if (answers.calls && answers.touch && answers.screenOriginal && answers.warranty) {
      setShowAlert(false);
      calculateDeductions();
      // setShowScreen(true);
      setShowScreenDefects(true);
      setShowDevice(false); // Hide the device section
    } else {
      setShowAlert(true);
    }
  };
 
  
  const handleFormData = (data) => {
    //console.log(data,"DEvice")
     onFormData(data);
 
  };

  const handleDeviceDetails = (data) => {
    onDeviceDetails(data)
  }

  

  return (
    <>
    <Grid container spacing={2} sx={{ mt: 4 }}>
      {showDevice && ( 
        <> 
        <Grid item xs={12} md={7}>
        <Box
                sx={{
                  p: 3,
                  border: '2px solid #4caf50',
                  borderRadius: '15px',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#f9f9f9',
                  height: isMobile ? 'calc(100vh - 150px)' : 'auto', // Adjust height for mobile screens
                  maxHeight: isMobile ? '80vh' : 'none', // Set a max height for mobile
overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile

              // Custom scrollbar styles
              '&::-webkit-scrollbar': {
                width: '4px', // Make the scrollbar very thin
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.0)', // Give the scrollbar a transparent color
                borderRadius: '10px', // Make it rounded
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.0)', // Slightly darker on hover
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', // Hide the scrollbar track
              },
                            }}
              >            
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2e7d32' }}>
                  Tell us more about your device!
                </Typography>           
                <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '1.1rem', color: '#555' }}>
                  What is the status of your mobile right now?
                </Typography>
                <Box sx={{ mt: 3 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>
                    1. Can you make and receive calls?
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#777', mb: 1 }}>
                    Check for cellular network connectivity difficulties on your mobile.
                  </Typography>
                  <RadioGroup name="calls" value={answers.calls} onChange={handleChange} row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio sx={{ color: '#4caf50' }} />}
                      label="Yes"
                      sx={{
                        backgroundColor: answers.calls === 'yes' ? '#d0f0c0' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#4caf50' },
                      }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio sx={{ color: '#f44336' }} />}
                      label="No"
                      sx={{
                        backgroundColor: answers.calls === 'no' ? '#ffcccb' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#f44336' },
                      }}
                    />
                  </RadioGroup>
            </Box>
            <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>
                    2. Is the touch screen working properly?
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#777', mb: 1 }}>
                    Make sure your mobile touch screen is working.
                  </Typography>
                  <RadioGroup name="touch" value={answers.touch} onChange={handleChange} row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio sx={{ color: '#4caf50' }} />}
                      label="Yes"
                      sx={{
                        backgroundColor: answers.touch === 'yes' ? '#d0f0c0' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#4caf50' },
                      }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio sx={{ color: '#f44336' }} />}
                      label="No"
                      sx={{
                        backgroundColor: answers.touch === 'no' ? '#ffcccb' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#f44336' },
                      }}
                    />
                  </RadioGroup>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>
                    3. Is the display on your phone original?
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#777', mb: 1 }}>
                    Choose "Yes" if the display was never replaced or was replaced by a licensed service center. Choose "No" if the display was replaced at a local store.
                  </Typography>
                  <RadioGroup name="screenOriginal" value={answers.screenOriginal} onChange={handleChange} row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio sx={{ color: '#4caf50' }} />}
                      label="Yes"
                      sx={{
                        backgroundColor: answers.screenOriginal === 'yes' ? '#d0f0c0' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#4caf50' },
                      }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio sx={{ color: '#f44336' }} />}
                      label="No"
                      sx={{
                        backgroundColor: answers.screenOriginal === 'no' ? '#ffcccb' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#f44336' },
                      }}
                    />
                  </RadioGroup>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>
                    4. Do you have a brand warranty on your phone?
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#777', mb: 1 }}>
                    You can receive a better deal if your mobile is under a brand's warranty with a legal bill.
                  </Typography>
                  <RadioGroup name="warranty" value={answers.warranty} onChange={handleChange} row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio sx={{ color: '#4caf50' }} />}
                      label="Yes"
                      sx={{
                        backgroundColor: answers.warranty === 'yes' ? '#d0f0c0' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#4caf50' },
                      }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio sx={{ color: '#f44336' }} />}
                      label="No"
                      sx={{
                        backgroundColor: answers.warranty === 'no' ? '#ffcccb' : 'transparent',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        margin: '5px 0',
                        '& .MuiFormControlLabel-label': { color: '#f44336' },
                      }}
                    />
                  </RadioGroup>
                </Box>

          
                <Box
    sx={{
      position: isMobile ? 'fixed' : 'static',
      bottom: isMobile ? 0 : 'auto',
      left: 0,
      width: '100%',
      p: 2,
      backgroundColor: isMobile ? '#fff' : 'transparent',
      boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
    }}
  >
    <Button
      variant="contained"
      sx={{
        
        backgroundColor: '#e91e63',
        color: '#fff',
        padding: isMobile ? '8px 16px' : '12px 24px',
        fontSize: isMobile ? '0.9rem' : '1.2rem',
        fontWeight: 'bold',
        borderRadius: '10px',
        width: '100%',
        '&:hover': { backgroundColor: '#c2185b' },
      }}
      onClick={handleContinue}
      endIcon={<ArrowForwardIcon />}
    >
      Continue
    </Button>
  </Box>

                <Snackbar
                  open={showAlert}
                  autoHideDuration={6000}
                  onClose={() => setShowAlert(false)}
                >
                  <MuiAlert
                    onClose={() => setShowAlert(false)}
                    severity="warning"
                    sx={{
                      backgroundColor: '#f57c00',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Please fill out all mandatory fields.
                  </MuiAlert>
                </Snackbar>
 
                </Box>
        </Grid>
     
        {!isMobile && (
              <Grid item xs={12} md={5}>
                <DeviceEvaluation selectedModel={selectedModel} formattedVariant={formattedVariant} answers={answers}  />
              </Grid>
            )}
      </>
       )}
    </Grid>
   
    {showScreen && (
 <Screen
 currentValue={currentValue}
 warrantyStatus={warrantyStatus}
 FixedPrice={FixedPrice}
 answers={answers}
 userLogDetails={userLogDetails}
 onFormData={handleFormData}
/>
    )}


      
{ShowScreenDefects && (
 <ScreenDefects
 selectedModel={selectedModel}
 formattedVariant={formattedVariant}
 currentValue={currentValue}
 warrantyStatus={warrantyStatus}
 rowDetails={rowDetails}
 FixedPrice={FixedPrice}
 answers={answers}
 userLogDetails={userLogDetails}
 onFormData={handleFormData}
 onDeviceDetails={handleDeviceDetails}

/>
    )}


{!isMobile && (
      <>
    <FAQ  />
<Footer />
</>

)}
   
    </>
  );
};

export default Device;
