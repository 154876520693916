import React from "react";
import { Box, Typography, Button, Card, CardContent, IconButton } from "@mui/material";
import { ArrowBack, LocationOn } from "@mui/icons-material";
import { useNavigate, useLocation } from 'react-router-dom';

const ManageAddress = () => {
    const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", p: 2 }}>
      
      {/* Header with Back Button */}
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate("/profile")}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">My Addresses</Typography>
      </Box>

      {/* Add New Address Button */}
      <Button 
        variant="outlined" 
        sx={{ borderRadius: 2, mb: 2, textTransform: "none", fontWeight: "bold" }}
        fullWidth
      >
        + Add New
      </Button>

      {/* Address Card */}
      <Card sx={{ borderRadius: 3, boxShadow: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOn color="primary" />
            <Typography variant="subtitle1" fontWeight="bold" ml={1}>Other</Typography>
          </Box>

          <Typography variant="body2">Mysore, Karnataka</Typography>
          <Typography variant="body2">
            #1427 2nd cross, vijay nagar 4th stage, surya bakery, Mysore, Karnataka, 570015
          </Typography>

          {/* Edit & Delete Options */}
          <Box display="flex" mt={2} gap={2}>
            <Typography variant="body2" color="error" sx={{ cursor: "pointer" }}>Edit</Typography>
            <Typography variant="body2" color="error" sx={{ cursor: "pointer" }}>Delete</Typography>
          </Box>
        </CardContent>
      </Card>

    </Box>
  );
};

export default ManageAddress;
