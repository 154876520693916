import React from 'react';
import {BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import AdminDashboardPage from './components/admin/AdminDashboard';
import StaffDashboard from './components/admin/StaffDashboard';
import LoginPage from './components/auth/LoginForm';
import RegisterPage from './components/auth/RegisterForm';
import FrontPage from './components/admin/FrontPage';
 import ProductType from './components/screens/ProductType';
import PartnerWithUs from './components/admin/PartnerWithUs';
import Terms from './components/admin/Terms';
import Cookies from './components/admin/Cookies';
import Privacy from './components/admin/Privacy';
import Contact from './components/admin/Contactus';
import About from './components/admin/Aboutus';
import Profile from './components/screens/ProfilePage';
import CustomerLoginInitial from './components/screens/CustomerLoginInitial';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Pickup from "./components/screens/Pickup";
import MyOrders from './components/screens/MyOrders';
import OrderDetails from './components/screens/OrderDetails';
import ManageAddress from "./components/screens/ManageAddress";
const AppRoutes = () => {
// usePageTracking();
return (
 <Router>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/admin-login" element={<LoginPage />} />
        {/* <Route path="/admin-register" element={<RegisterPage />} /> */}
        <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/admin-Logged"element={<ProtectedRoute element={AdminDashboardPage} />}
        />
        <Route path="/staff" element={<StaffDashboard />}/>
        <Route path="/sell/:productType" element={<ProductType />} />
    
        <Route path="/partner" element={<PartnerWithUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cookies-policy" element={<Cookies />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<CustomerLoginInitial/>} />
        <Route path="/pickup" element={<Pickup/>} />
        <Route path="/my-orders" element={<MyOrders/>} />
        <Route path="/order-details" element={<OrderDetails/>} />
        <Route path="/manage-address" element={<ManageAddress/>} />

      </Routes>
    </Router>
 
  );
};

export default AppRoutes;
