import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Alert,
  CircularProgress,
  useMediaQuery, 
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginImg from '../../assets/images/Login.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

import SellNow from './SellNow';
import Warranty from './Warranty';
import axios from 'axios';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import { config } from '../../config';



                  
const LoginSignupPage = ({
selectedModel,
deviceAnswers,
formattedVariant,
warrantyStatus,
goBack,
currentValue,
rowDetails,
answers,
FixedPrice,
ScreenselectedOptions,
DefectsselectedOptions,
functionalSelectedOptions,
accSelectedOptions,
userLogDetails,
onFormData,
selectedWarranty
        
        }) => {
  const [currentPage, setCurrentPage] = useState('loginpage');
  const [showState,setShowState] = useState('PhoneNumber');
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [isResendAllowed, setIsResendAllowed] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [formData, setFormData] = useState({name: '',email: '',phoneNumber: '', BrandName : rowDetails["Brand name"], Model : rowDetails["Model"],formattedVariant:formattedVariant,visitedDate:new Date(),referral:""});
  const[userLogDetails1,setuserLogDetails1] = useState(null);
const [errors, setErrors] = useState({});
   const [newUser, setNewUser] = useState(false);
   const [loading, setLoading] = useState(false);
   const [uniqueId,setuniqueId] = useState(0);
   const [isChecked, setIsChecked] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    let timer;
    if (!isResendAllowed) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsResendAllowed(true);
            return 60;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendAllowed]);

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.Api}send-otp`, {phoneNumber});
      // //console.log(response,"response...")
      setLoading(false);
      if (response.status === 200) {
        setIsOtpSent(true);
        setIsResendAllowed(false);
        setShowState('otp');
      } else {
        // alert(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      // alert('Failed to send OTP');
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.Api}verify-otp`, { phoneNumber, otp });
      setLoading(false);
      // //console.log(response,"verify")
      if (response.status === 200) {
        setCurrentPage('sellNowPage');
        setShowState(null);
        setOtpError('');
      } else {
        setOtpError(response.data.message);
        setShowState('otp')
      }
    } catch (error) {
      setLoading(false);
      setOtpError('Failed to verify OTP');
    }
  };

  
  const handleChange = (e, type) => {
    const { name, value } = e.target;
  
    let newErrors = { ...errors };
  
    if (type === "phone") {
      // Allow only numbers
      const numericValue = value.replace(/\D/g, "");
  
      newErrors.phoneNumber =
        numericValue.length === 0
          ? "Phone Number is Required"
          : numericValue.length < 10
          ? "Phone number must be 10 digits"
          : "";
  
      setFormData({
        ...formData,
        [name]: numericValue, // Store only numbers
      });
    } else if (type === "email") {
      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      newErrors.email =
        value.trim() === ""
          ? "Email is Required"
          : !emailRegex.test(value)
          ? "Enter a valid email address"
          : "";
  
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (type === "name") {
      // Name validation: Cannot be empty
      newErrors.name = value.trim() === "" ? "Name is Required" : "";
  
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  
    setErrors(newErrors);
  };
  
  
  const handlePhoneNumberSubmit = async () => {
    // Check if phone number is empty or invalid
    if (!formData.phoneNumber || formData.phoneNumber.length < 10) {
      setErrors({
        ...errors,
        phoneNumber: !formData.phoneNumber
          ? "Phone Number is Required"
          : "Phone number must be 10 digits",
      });
      return; // Stop API call if validation fails
    }
  
    const userType = 1;
    setLoading(true);
  
    try {
      const response = await axios.post(`${config.Api}check-phone-number`, {
        phoneNumber: formData.phoneNumber,
        userType,
      });
  
      setLoading(false);
  
      if (response.data.exists) {
        const { name, email, phoneNumber } = response.data.user;
        setuserLogDetails1({ name, email, phoneNumber });
        setCurrentPage("sellNowPage");
      } else {
        setNewUser(true);
        setShowState("details");
        setFormData({ ...formData, phoneNumber: formData.phoneNumber });
      }
    } catch (error) {
      setLoading(false);
      alert("Error checking phone number");
    }
  };
  const handleContinue = async () => {
    console.log(formData,"LOGIN");
    // return;
    setLoading(true);
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (Object.keys(newErrors).length === 0) {
      setuserLogDetails1({ name: formData.name, email: formData.email, phoneNumber: formData.phoneNumber });
      console.log(formData,"login");
      // return;

      try {
      const url = uniqueId ? `${config.Api}userpickup/${uniqueId}` : config.Api+'userpickup';
      const method ='POST';
      const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      //console.log(formData,"LOGIN");

        if (response.ok) {
          const result = await response.json(); // Parse the JSON response
          // //console.log(result,"result")
          const savedPickupDetails = result.data;
          // //console.log('Saved Pickup Details:', savedPickupDetails);
          setuniqueId(savedPickupDetails?.uniqueId);
          // Clear form data after successful submission if needed
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            visitedDate : null,
            referral : "",

           });
           setLoading(false);
        } else {
          // setPickupDetails([]);
          throw new Error('Failed to submit details');
        }      setLoading(false);
      } catch (error) {
        // //console.log(error)
        setLoading(false);
        }finally {
        setLoading(false); // Stop loading after processing
      }
      if (onFormData) {
        onFormData(formData); // Send data to parent
      }
      // setShowState('otp');
      setLoading(false);
      setCurrentPage('sellNowPage');
    } else {
      setErrors(newErrors);
      setLoading(false);
    } 
  };
  

  return (
    <>
     {currentPage === "loginpage" && (
        <Grid container spacing={2}  justifyContent="center">
      {/* <Paper elevation={3} style={{ display: 'flex', padding: '20px',maxWidth:"1000px" }}> */}
        <Grid container spacing={2}>
          {!isMobile && (
            <Grid item xs={12} md={6} sx={{ backgroundColor: '#36225b', padding: '20px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img
                src={LoginImg ? LoginImg : "https://via.placeholder.com/300x300?text=Login+Image"}
                alt="Login Illustration"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
          </Grid>
          )}
          
        
          <Grid  item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', borderRadius: '12px',paddingLeft:"6%"}}>
  {/* Brand Details Section */}
  {showState === "PhoneNumber" && (
<>
  
  <Box
  sx={{
    border: '1px solid #D3D3D3', // Light border
    borderRadius: '8px', // Rounded corners
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
    backgroundColor: 'white', // White background
    padding: 2, // Padding around the content
    display: 'flex',
    alignItems: 'center',
    marginBottom: 3,
    width:"100%"
  }}
>
  {/* Image Section */}
  <img
    src= {`${rowDetails?.[" Image URL"]}`}
    alt={`${rowDetails?.["Brand name"]} ${rowDetails?.["Model"]}`}
    style={{
      width: 65,
      height: 90,
      borderRadius: '4px', // Slightly rounded image corners
      marginRight: 16, // Space between image and text
    }}
  />
  {/* Text Content */}
  <div>
    <Typography variant="body" style={{fontWeight:"bold"}}>
      {`${rowDetails?.["Brand name"]} ${rowDetails?.["Model"]} (${formattedVariant})` || 'N/A'}
    </Typography>
    <Typography variant="h6" color="darkgray" fontWeight={"bold"} style={{marginTop:"5px"}}>
     Selling Price
    </Typography>
    <Typography variant="h5" sx={{ color: 'red', fontWeight: 'bold' }} style={{marginTop:"5px"}}>
      ₹ XX,XXX
    </Typography>
  </div>
</Box>


  {/* Lock Icon and Text */}
  <Box sx={{ textAlign: 'center',    
   padding: 2,  
    display: 'flex',
    alignItems: 'center',
    marginBottom: 3,
    background:"#c1f0ea",
    borderRadius:"12px",
    gap:"10px",
    width:"100%"}}>
    <LockIcon sx={{ fontSize: 25, color: '#0e7f71',marginTop:"8px" }} />
    <Typography variant="body" style={{fontWeight:"bold"}} sx={{ color: '#0e7f71', marginTop: 1}}>
      Login to unlock the best price
    </Typography>
  </Box>
  </>
)}

  {/* Mobile Number Input Section */}
  {showState === "PhoneNumber" && (
    <>
      <TextField
        fullWidth
        name="phoneNumber"
        label="Enter your Mobile"
        type="tel"
        value={formData.phoneNumber}
        onChange={(e) => handleChange(e,"phone")}
        inputProps={{
          maxLength: 10, // Restrict max length to 10
          pattern: "[0-9]*", // Ensure numeric input
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocalPhoneIcon sx={{ color: '#3cb7a9' }} />
            </InputAdornment>
          ),
        }}
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber || " "}
        sx={{ marginBottom: 3 }}
      />

      {/* Terms and Conditions */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Checkbox sx={{ color: '#3cb7a9' }}   
        checked={isChecked}
          onChange={(event) => setIsChecked(event.target.checked)} />
        <Typography variant="body2">
          I agree to the <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#3cb7a9', textDecoration: 'underline' }}>Terms and Conditions</a> &{' '}
          <a href="/privacy-policy"  target="_blank" rel="noopener noreferrer"  style={{ color: '#3cb7a9', textDecoration: 'underline' }}>Privacy Policy</a>
        </Typography>
      </Box>

      {/* Continue Button */}
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: isChecked ? '#3cb7a9' : '#c2c2c2', // Greyed-out background when disabled
          color: 'white',
          fontWeight: 'bold',
          '&:hover': { backgroundColor: isChecked ? '#2c8c84' : '#c2c2c2' },
          height: 48,
        }}
        onClick={handlePhoneNumberSubmit}
        endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
        disabled={!isChecked || loading}
      >
        {loading ? 'Sending...' : 'Continue'}
      </Button>
    </>

            )}
           
        
        
        {showState === 'otp' && (
          <>
            <Button
              variant="contained"
              fullWidth
              sx={{ backgroundColor: '#D8F2E8', color: '#06A89D', marginBottom: '20px', '&:hover': { backgroundColor: '#06A89D', color: '#fff' } }}
              onClick={handleSendOtp}
              disabled={!isResendAllowed && isOtpSent}
            >
              {isResendAllowed ? 'Send OTP' : `Resend OTP in ${countdown}s`}
            </Button>
            <Typography variant="body2" gutterBottom>
              Enter OTP
            </Typography>
            <Grid container spacing={1}>
              {[...Array(6)].map((_, index) => (
                <Grid item xs={2} key={index}>
                  <TextField
                    variant="outlined"
                    value={otp[index] || ''}
                    onChange={(e) => {
                      const newOtp = otp.split('');
                      newOtp[index] = e.target.value;
                      setOtp(newOtp.join(''));
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            {otpError && <Alert severity="error" sx={{ mt: 2 }}>{otpError}</Alert>}
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
              onClick={handleVerifyOtp}
              endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
              disabled={loading}
            >
                      {loading ? 'Verifying...' : 'Verify OTP'}
                      </Button>
            <FormControlLabel
              control={<Checkbox name="checkedA" />}
              label={
                <Typography variant="body2">
                  I agree to the <Link to="/terms" style={{ textDecoration: "underline" }} onClick={() => navigate('/terms')}>Terms and Conditions</Link> & <a href="#privacy">Privacy Policy</a>
                </Typography>
              }
              sx={{ mt: 2 }}

            />
          </>
        )}
        {showState === 'details' && (
          <div style={{padding:"2%"}}>
       <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: '#e8f7f7',
        padding: '2%',
        borderRadius: '8px',
        maxWidth: 400,
        margin: 'auto',
      }}
    >
      <Grid item xs={12}>
        <h2 style={{ margin: 0, fontSize: '1.5rem', color: '#007f7f', textAlign: 'center' }}>
         Signup
        </h2>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="name"
          placeholder="Name"
          type="text"
          value={formData.name}
          onChange={(e) => handleChange(e,"name")}
          error={!!errors.name}
          helperText={errors.name}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              borderRadius: '8px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle style={{ color: '#007f7f' }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              fontSize: '1rem',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="email"
          placeholder="Email"
          type="email"
          value={formData.email}
          error={!!errors.email}
          helperText={errors.email}

          onChange={(e) => handleChange(e,"email")}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              borderRadius: '8px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <Email style={{ color: '#007f7f' }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              fontSize: '1rem',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="referral"
          placeholder="Referral Code (if any)"
          type="text"
          value={formData.referral}
          onChange={(e) => handleChange(e,"referral")}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              borderRadius: '8px',
            },
          }}
          inputProps={{
            style: {
              fontSize: '1rem',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#33c6b7',
            color: '#fff',
            fontSize: '1rem',
            padding: '12px 0',
            borderRadius: '25px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#2da69d',
            },
          }}
          onClick={handleContinue}
          endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Continue'}
        </Button>
      </Grid>
    </Grid>

          </div>
        )}
         </Grid>
        </Grid>
      {/* </Paper> */}
      </Grid> 
    
    
    )}
    
     <Grid container spacing={2} sx={{ mt: 4 }}>
        <>
        {currentPage === 'sellNowPage' && 
        <SellNow 
        selectedModel={selectedModel}
        selectedWarranty={selectedWarranty}
         formattedVariant={formattedVariant}
          currentValue={currentValue}
           warrantyStatus={warrantyStatus}
            rowDetails={rowDetails}
             answers={answers}
              FixedPrice={FixedPrice} 
              deviceAnswers={deviceAnswers}
               ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions} 
                functionalSelectedOptions={functionalSelectedOptions}
                 accSelectedOptions={accSelectedOptions} 
                 userLogDetails={userLogDetails1} 
                 onFormData={onFormData}
                 
                 />
                 }

                 
        {currentPage === 'warrantyPage' &&
         <Warranty 
        //  selectedWarranty={selectedWarranty}
         selectedModel={selectedModel} 
         formattedVariant={formattedVariant}
          currentValue={currentValue} 
          warrantyStatus={warrantyStatus}
          rowDetails={rowDetails}
            answers={answers}
             FixedPrice={FixedPrice}
              deviceAnswers={deviceAnswers}
               ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions}
                 functionalSelectedOptions={functionalSelectedOptions}
                  accSelectedOptions={accSelectedOptions}
                   userLogDetails={userLogDetails1} 
                   onFormData={onFormData}
                   />}
        </>
       </Grid>
       </>
  );
};

export default LoginSignupPage;