import React, { useState } from 'react';
import {
  Container,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  useMediaQuery
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Accessories from './Accessories';
import CustomerLogin from './CustomerLogin'
import SellNow from './SellNow';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import {useSelector,useDispatch} from 'react-redux';
 import { handleselectedWarranty } from '../../redux/WrapperSlice';
const MobileAgeSelector = ({deviceAnswers,formattedVariant,warrantyStatus,currentValue,rowDetails,answers,FixedPrice,userLogDetails,onFormData,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions}) => {
 const [currentPage, setCurrentPage] = useState(warrantyStatus ? 'mobileAgeSelector' : 'loginSignup' );
 const dispatch = useDispatch();
  const[WarrCurVal,setWarrCurVal] = useState(currentValue);
  const [selectedWarranty, setSelectedWarranty] = useState('');
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleFormData = (data) => {
  onFormData(data)
    
  };

  const handleWarrantySelection = (event) => {
    const value = event.target.value;
    setSelectedWarranty(value);
    dispatch(handleselectedWarranty(value));
    let deduction = 0;
    if (value === 'below3months') {
      deduction = rowDetails["<3 months"];
    } else if (value === '3to6months') {
      deduction = rowDetails["3 -6 months"];
    } else if (value === '6to11months') {
      deduction = rowDetails["6-11 month"];
    } else if (value === 'above11months') {
      deduction = rowDetails["> 11 months"];
    }
  

    let newValue = currentValue + deduction;
    // //console.log(newValue,"new value after deduction")

    setWarrCurVal(newValue);
  };

  const handleContinue = () => {
    setCurrentPage('loginSignup');
  };

  

  if (currentPage === 'loginSignup' && (!userLogDetails || !userLogDetails.name || !userLogDetails.email || !userLogDetails.phoneNumber)) {
    return <CustomerLogin 
    selectedModel={selectedModel} 
    selectedWarranty={selectedWarranty}
    formattedVariant={formattedVariant} 
    currentValue={WarrCurVal} 
    warrantyStatus={warrantyStatus} 
    rowDetails={rowDetails}
     answers={answers}
      deviceAnswers={deviceAnswers} 
      FixedPrice={FixedPrice}
       userLogDetails={userLogDetails}
        onFormData={handleFormData}
        ScreenselectedOptions={ScreenselectedOptions} 
        DefectsselectedOptions={DefectsselectedOptions} 
        functionalSelectedOptions={functionalSelectedOptions} 
        accSelectedOptions={accSelectedOptions} 
        />;
     
   }

   if (currentPage === 'loginSignup' &&(userLogDetails || userLogDetails.name || userLogDetails.email || userLogDetails.phoneNumber) ) {
    return <SellNow 
    selectedModel={selectedModel} 
    selectedWarranty={selectedWarranty}
    formattedVariant={formattedVariant}
     currentValue={WarrCurVal}
      warrantyStatus={warrantyStatus}
       rowDetails={rowDetails} 
       answers={answers}
        deviceAnswers={deviceAnswers} 
        FixedPrice={FixedPrice} 
        userLogDetails={userLogDetails} 
        onFormData={handleFormData}
        ScreenselectedOptions={ScreenselectedOptions} 
        DefectsselectedOptions={DefectsselectedOptions} 
        functionalSelectedOptions={functionalSelectedOptions} 
        accSelectedOptions={accSelectedOptions} 
        
        />;
     
   }
   

 

  return (

    <Grid container spacing={2} mt={"4"}>
      {currentPage === "mobileAgeSelector" && (
        <Box sx={{ mt: 4 }}>
          {/* <Card sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', borderRadius: '12px', p: 3 }}> */}
          {/* <CardContent> */}
              <Typography variant="h5" align="center" gutterBottom>
                What is your mobile age?
              </Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                (Because you chose your device is under brand's warranty)
              </Typography>

                  <Box
                                                              sx={{
                                                                // position: isMobile ? 'fixed' : 'static',
                                                                // bottom: isMobile ? 0 : 'auto',
                                                                // height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
                                                                // overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
                                                                // // paddingBottom: { xs: '80px', md: '0' },
                                                                // width: '100%',
                                                                p: 3,
                                                                   // Hide scrollbar (cross-browser solution)
                                                            // '&::-webkit-scrollbar': {
                                                            //   display: 'none', // Hide scrollbar for WebKit browsers
                                                            // },
                                                            // '&::-webkit-scrollbar-thumb': {
                                                            //   backgroundColor: 'transparent', // Ensure it's completely transparent
                                                            // },
                                                            // '&::-webkit-scrollbar-track': {
                                                            //   backgroundColor: 'transparent', // Hide the scrollbar track
                                                            // },
                                                            // scrollbarWidth: 'none', // Hide scrollbar for Firefox
                                                          }}
                                                            >
                          
              <RadioGroup value={selectedWarranty} onChange={handleWarrantySelection}>
      <Grid container spacing={2}>
        {[
          { value: 'below3months', label: 'Below 3 months', subtitle: 'Valid bill mandatory' },
          { value: '3to6months', label: '3 months - 6 months', subtitle: 'Valid bill mandatory' },
          { value: '6to11months', label: '6 months - 11 months', subtitle: 'Valid bill mandatory' },
          { value: 'above11months', label: 'Above 11 months', subtitle: '' },
        ].map((option) => (
          <Grid item key={option.value} xs={12}>
            <FormControlLabel
              value={option.value}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: '#4caf50',
                    },
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column', // Align items vertically
                    alignItems: 'flex-start', // Align content to the left
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      borderRadius: '8px',
                      transition: 'background-color 0.3s ease-in-out',
                    },
                    p: 1,
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="body1">{option.label}</Typography>
                  {option.subtitle && (
                    <Typography variant="caption" color="textSecondary">
                      {option.subtitle}
                    </Typography>
                  )}
                </Box>
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  display: 'flex',
                  flexDirection: 'column', // Ensure vertical alignment
                  alignItems: 'flex-start',
                },
                '& .MuiFormControlLabel-root': {
                  display: 'block',
                  margin: 0,
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
    </Box>


    <Box
           sx={{
             position: isMobile ? 'fixed' : 'static',
             bottom: isMobile ? 0 : 'auto',
             left: 0,
             width: '100%',
             p: 1,
             backgroundColor: isMobile ? '#fff' : 'transparent',
             boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
           }}
         >

<Button
variant="contained"
sx={{
backgroundColor: '#4caf50',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#45a049',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>
         </Box>
         
        </Box>
      )}

{!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}
   </Grid>

   
  );
};

export default MobileAgeSelector;
