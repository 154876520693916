import React, { useState, useEffect } from 'react';
import {
 Container, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Button, IconButton, Box, Dialog,
 useMediaQuery,BottomNavigation, BottomNavigationAction,Paper
} from '@mui/material';
 import { useNavigate } from 'react-router-dom';
import CityDialog from '../commonForms/Locations';
 import Navbar from '../commonForms/Navbar';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import NoImage from "../../assets/images/Thumbnail.jpg"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation } from 'react-router-dom';
 import AppBarComponent from '../commonForms/AppBar';
import { config } from '../../config';
import { Search, Info as InfoIcon,
  Business as BusinessIcon, } from '@mui/icons-material';




const brands = [
  { name: 'Apple Iphone', image: 'https://i.pinimg.com/736x/18/1b/b9/181bb9da20d9c75610a671edb5c8bb1f.jpg' },
  { name: 'Samsung', image: 'https://i.ibb.co/BN8PKw0/pngimg-com-samsung-logo-PNG9.png' },
  {name : "One Plus", image : "https://logos-world.net/wp-content/uploads/2023/03/OnePlus-Logo.jpg"},
  { name: 'Realme', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Realme_logo.svg/2560px-Realme_logo.svg.png' },
  { name: 'OPPO', image: 'https://www.michael-young.com/wp-content/uploads/2020/03/Oppo-Logo.png' },
  { name: 'VIVO', image: 'https://1000logos.net/wp-content/uploads/2022/02/Vivo-Logo.jpg' },
  { name: 'Xiaomi', image: 'https://madaboutkitchen.in/wp-content/uploads/2021/04/Xiaomi-redmi-logo.jpg' },
  { name: 'Poco', image:'https://i.ibb.co/1s5F7YK/POCO-Emblem.png' },
  { name: 'IQOO', image:'https://images.seeklogo.com/logo-png/46/1/iqoo-logo-png_seeklogo-469682.png' },
  { name: 'Nothing', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Nothing_Logo.webp/640px-Nothing_Logo.webp.png' },
  { name: 'Google', image: 'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png' },
  { name: 'Motorola', image: 'https://logolook.net/wp-content/uploads/wordpress-popular-posts/28151-featured-225x127.png' },
  { name: 'NOKIA', image: 'https://static.vecteezy.com/system/resources/previews/020/927/540/non_2x/nokia-logo-brand-phone-symbol-black-design-finland-mobile-illustration-free-vector.jpg' },
  { name: 'ASUS', image: 'https://logos-world.net/wp-content/uploads/2020/07/Asus-Logo.png' },
];
 
const services = [
  { name: 'Sell Phone', image: 'https://i.ibb.co/1rM1WDg/Sell-phone.png' },
  { name: 'Sell Laptop', image: 'https://i.ibb.co/B2SRwgb/Sell-laptop.png' },
  { name: 'Sell iMac', image: 'https://i.ibb.co/vL6vMS7/Sell-i-Mac.png' },
  { name: 'Sell Tablet', image: 'https://i.ibb.co/NpnYdhp/Sell-Tablet.png' },
  { name: 'Sell Earbuds', image: 'https://i.ibb.co/3sPbstL/sell-Airpods.png' },
  { name: 'Sell Smartwatch', image: 'https://i.ibb.co/4sMtWL4/Sell-smart-watch.png' },
  { name: 'Sell   Camera', image: 'https://i.ibb.co/nrqZD76/Sell-camera.png' },];

  const selling = [  
    { name: 'Repair Phone', image: 'https://i.ibb.co/tL2rGdc/repair-Phone.png' },
    { name: 'Buy Phone', image: 'https://i.ibb.co/RjKnNJW/buy-phone.png' },
    { name: 'Buy Laptop', image: 'https://i.ibb.co/rFYLW23/Buy-laptop.png' },
    ]





const FrontPage = () => {
  const location = useLocation();
  const name = location.state?.name;
  const email = location.state?.email;
  const phoneNumber = location.state?.phoneNumber;
  const navigate = useNavigate();
  const [openCityDialog, setOpenCityDialog] = React.useState(false);  // New state for the dialog
  const [selectedCity, setSelectedCity] = useState('Mysore');

  const [dataFromBackend, setDataFromBackend] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    // Simulate initial data fetch or use a function to load data
    fetchData(setSelectedCity, 1);
  }, [setSelectedCity]);



  // Check if the CityDialog has been shown before
  useEffect(() => {
    const isDialogShown = localStorage.getItem("cityDialogShown");

    if (!isDialogShown) {
      // Show the dialog for the first time
      setOpenCityDialog(true);
      // Mark the dialog as shown
      localStorage.setItem("cityDialogShown", "true");
    }
  }, []);


    useEffect(() => {
    if (location.state?.showCityDialog === false) {
      setOpenCityDialog(false);  // Close the dialog only if `showCityDialog` is explicitly set to false
    }
  }, [location.state?.showCityDialog]);

  const fetchData = (City, page) => {
    setLoading(true);
    fetch(`${config.Api}sheet-data?City=${City}&page=${page}&limit=10`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      mode:"no-cors"
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (page === 1) {
        setDataFromBackend(data); // For the first page, replace data
      } else {
        setDataFromBackend([...dataFromBackend, ...data]); // For subsequent pages, append data
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    });
  };


  const handleCitySelect = (cityName) => {
    fetchData(cityName,1);
    setSelectedCity(cityName);
     
  };
  
  
 

 
  const handleCloseCityDialog = () => {
    setOpenCityDialog(false);
  };


 

   

 



  const handleServiceClick = (serviceName) => {
    navigate(`/sell/${serviceName.toLowerCase().replace(' ', '-')}`, { state: { serviceName, data: dataFromBackend, city:selectedCity,page : currentPage, name: name,email:email,phoneNumber:phoneNumber} });
  };
  
  const handleBrandClick = (brand) => {
    if (brand && brand.name) {
      navigate(`/sell/${brand.name.toLowerCase().replace(' ', '-')}`, { 
        state: { brandName: brand.name, data: dataFromBackend, city: selectedCity, page: currentPage, name: name,email:email,phoneNumber:phoneNumber } 
      });
    } else {
    }
  };

 

  const sliderImages = [
    {
      // id:1,image : "https://i.ibb.co/MnN1dpT/1.png", 
      // id:1,image : "https://i.ibb.co/8NVs3F2/margaret.png", 
      // id:1,image : "https://i.ibb.co/Jvr6ZDp/christmas.jpg", 
      // id:1,image : "https://i.ibb.co/Jvr6ZDp/christmas.jpg", 
      // id:1,image : "https://i.ibb.co/WgM4R7H/gh.jpg", 
      id:1,image : "https://i.ibb.co/y6VVgRR/margaret.jpg", 
      path:"/sell/sell-phone"
    },
    {id:2,image : "https://i.ibb.co/Zhpmc5m/2.png", 
      //  path:"https://i.ibb.co/94ZNDSP/9.png"
     },
     {id:3,image : "https://i.ibb.co/7QHDRKs/3.png", 
       path:"https://forms.gle/8DKs2vx2grkdMLU27"
     },
     {id:4,image : "https://i.ibb.co/QpFSpjr/4.png", 
       path:"https://forms.gle/s7p1z8LYbo3Y1zEk8"
     }
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div>
<AppBarComponent userDetails={{name,email,phoneNumber}} onCitySelect={handleCitySelect} selectedCity1={selectedCity}/>
 <Navbar/> 
{/* Body Section */}
      <Container  style={{
    marginTop: isMobile ? '0px' : '20px',
  }}
  className="sliderContainerRoot">
      <Box 
      sx={{ 
        bgcolor: '#f5f5f5', 
        p: { xs: 1, sm: 2 }, 
        textAlign: 'center', 
        borderRadius: "10px", 
  
      }} 
>
  <Carousel 
    autoPlay 
    interval={4000} 
    infiniteLoop 
    showThumbs={false} 
    showStatus={false} 
    dynamicHeight={false} 
  >
    {sliderImages.map((image, index) => (
      <div 
        key={index} 
        style={{ 
          height: '100%',   // Ensure the div takes the full height
          display: 'flex',  // Use flexbox to ensure the image covers the entire space
          justifyContent: 'center', 
          alignItems: 'center'
        }}
      >

<a 
        href={image?.path || '#'} 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ 
          display: 'block',       // Make the anchor a block element
          width: '100%',          // Take up full width
          height: '100%',         // Take up full height
        }}
      >
        <img 
          src={image ? image.image : NoImage} 
          alt={`slide-${index}`} 
          style={{ 
            height: '100%',    // Ensure the image takes the full height
            width: '100%',     // Ensure the image covers the full width
            objectFit: 'cover' // Ensure the image covers the entire box without distortion
          }} 
        />
         </a>
      </div>
    ))}
  </Carousel>
</Box>

    <Typography className='lets-get-started' variant="h4" sx={{ fontSize: { xs: '1.2rem', sm: '1.8rem' } }}>Sell now for instant cash!</Typography>
      {/* <Typography className='lets-get-started' variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}> Grab the best deal on your old device!</Typography>   */}
      <Box sx={{ marginTop: '30px', textAlign: 'center' }}>

      <Grid container justifyContent="center">
  <Grid container spacing={2} justifyContent="center" item xs={12}>
    {services.map((service) => (
      <Grid 
        item 
        xs={4} 
        sm={4} 
        md={2} 
        lg={1.5} 
        key={service.name} 
        onClick={() => handleServiceClick(service.name)}
      >
        <Card >
          <CardActionArea>
            <CardMedia
              component="img"
              alt={service.name}
              width={"100"}
              image={service.image ? service.image : NoImage}
              title={service.name}
              style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px', padding: "6%" }}
            />
            <CardContent>
              <Typography 
                gutterBottom 
                variant="h6" 
                component="div" 
                sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} 
                className='our-services'
              >
                {service.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
</Grid>
<Typography style={{marginTop:'4%'}} variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem'}, fontWeight:{xs:"700",sm:"700",md:"700" }, marginBottom:{sm:"2%"}}}>Our Services</Typography>

        <Grid container spacing={2} justifyContent="center" xs={12}>
          {selling.map((service) => (
             <Grid   item 
             xs={4} 
             sm={4} 
             md={2} 
             lg={2} 
              key={service.name} onClick={() => handleServiceClick(service.name)}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={service.name}
                    // height="140"
                    width={"100"}
                    image={service.image ? service.image : NoImage}
                    title={service.name}
                    style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px', padding: "6%" }}
                    />
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} className='our-services'>
                  {service.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}

 
 



        </Grid>
        </Box>
        <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem'}, fontWeight:{xs:"700",sm:"700",md:"700" }, marginBottom:{sm:"2%"}}}>Select a Brand</Typography>
     
        <Grid container spacing={2} justifyContent="center">
          {brands.map((brand) => (
          <Grid item xs={6} sm={6} md={3} lg={2} key={brand.name} onClick={() => handleBrandClick(brand)}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column', // Ensure content stacks vertically
                }}
                >
              
              <CardActionArea  
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column', // Center the content vertically inside the card
                height: '100%',
              }}>
              <CardMedia
               component="img"
               alt={brand.name}
               height="100"
               image={brand.image}
               title={brand.name}
                     sx={{
                      objectFit: 'contain',
                      maxWidth: '60px',
                      height: '100px', // Keep the height uniform
                    }}
                   />

                  
               
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Box>
        <FAQ/>
         <Footer/>
     
      </Container>


       

      <CityDialog
        openCityDialog={openCityDialog}
        handleCloseCityDialog={handleCloseCityDialog}
        setSelectedCity={setSelectedCity}
        onCitySelect={handleCitySelect}
      />




    </div>
  );
};

export default FrontPage;
