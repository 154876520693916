import React, { useState, useEffect } from 'react';
import { Box, Typography,
   Grid,
    Button,
     Paper,
      Container,
       Snackbar,
        Alert as MuiAlert,
        useMediaQuery
       } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Charger from '../../assets/images/charger.png'
import Box1 from '../../assets/images/Box.png'
import Bill from '../../assets/images/Bill.jpeg'
import { useDispatch, useSelector } from 'react-redux';

import DeviceEvaluation from '../commonForms/DeviceEvaluation'; // Update the import path as necessary
import Functional from './Functional';
import Warranty from './Warranty';
import CustomerLogin from './CustomerLogin'
import SellNow from './SellNow';

import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import {handleaccSelectedOptions} from "../../redux/WrapperSlice";


const Accessories = ({ selectedModel,  deviceAnswers,rowDetails,formattedVariant,currentValue,warrantyStatus,goBack,FixedPrice,answers,showWarrantyPage,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,userLogDetails,onFormData}) => {
  const dispatch = useDispatch();

  const [selectedAccessories, setSelectedAccessories] = useState([]);
   const [showEvaluation, setShowEvaluation] = useState(true);
   const [showWarranty,setshowWarranty] = useState(false);
  const [AccCurrVal,setAccCurrVal] = useState(currentValue);

  useEffect(() => {
   }, [selectedAccessories]);
  const handleSelection = (accessory) => {
    setSelectedAccessories((prev) => {
      const isSelected = prev.some((item) => item.label === accessory.label);
      if (isSelected) {
             dispatch(handleaccSelectedOptions([prev.filter((item) => item.label !== accessory.label)]))
        
        return prev.filter((item) => item.label !== accessory.label);
      } else {
        dispatch(handleaccSelectedOptions([...prev, accessory]))
        return [...prev, accessory];
      }
    });
  };

  const handleFormData = (data) => {
      onFormData(data)
    
  };

  const accessories = [
    { label: 'Original Charger of Device', imageUrl: Charger ,deduction: rowDetails['Charger']},
    { label: 'Box with same IMEI', imageUrl: Box1 ,deduction: rowDetails['Box'] },
    { label: 'Bill Of the Device', imageUrl: Bill ,deduction: rowDetails['Bill']},
  ];

  const handleContinue = () => {
    if (!FixedPrice) {
      let deduction = 0;
      const notSelectedAccessories = accessories.filter((acc) => !selectedAccessories.some(selected => selected.label === acc.label));
 
      if (selectedAccessories.length === 0) {
        // 3 not selected => Deduct the maximum (least negative) price from the list
        deduction = Math.min(...accessories.map((acc) => acc.deduction));
      } else if (selectedAccessories.length === 1) {
        // 1 selected and 2 not selected => Deduct the maximum (least negative) price from the not selected list
        deduction = Math.min(...notSelectedAccessories.map((acc) => acc.deduction));
       } else if (selectedAccessories.length === 2) {
        // 2 selected and 1 not selected => Deduct the price of the not selected accessory
        deduction = notSelectedAccessories[0].deduction;
      } // No deduction if all 3 are selected

       const updatedCurrentValue = AccCurrVal + deduction;
 
      setAccCurrVal(updatedCurrentValue);
    }
      setShowEvaluation(false);  
      setshowWarranty(true);
    
    
    
   

  };

 

 

  const isMobile = useMediaQuery('(max-width:600px)');


  return (
    <>
      {showEvaluation && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
           
            <Typography
                  variant="h5"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  Do you have the following?
                  </Typography>
                  <Typography
                  variant="h6"
                  className="conditionssubheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >Please select accessories which are available
                </Typography>
          
           
              <Grid container spacing={4} justifyContent="center" style={{marginBottom:"10%"}}>
                {accessories.map((accessory, index) => (
                  <Grid item xs={6} sm={6} md={4} key={index}>
                       <Box
                      onClick={() => handleSelection(accessory)}
                                  sx={{
                      cursor: 'pointer',
                      border: selectedAccessories.some((item) => item.label === accessory.label)  ? '2px solid green' : '1px solid #ddd',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      textAlign: 'center',
                      boxShadow:selectedAccessories.some((item) => item.label === accessory.label) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                      },
                      height: '200px', // Set a fixed height for uniformity
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                 >           
                
                   
                      <img
                        src={accessory.imageUrl}
                        alt={accessory.label}
                         style={{
                        width: '100%',
                        height: '120px', // Adjust height to balance the overall layout
                        objectFit: 'contain',
                        borderBottom: '1px solid #ddd',
                        padding:"7%"
                      }}
                      />
                        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: selectedAccessories.some((item) => item.label === accessory.label)  ? 'green' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        > {accessory.label}
        
        </Typography>
        </Box>
                  </Grid>
                ))}
              </Grid>
          
             
        
              <Box
           sx={{
             position: isMobile ? 'fixed' : 'static',
             bottom: isMobile ? 0 : 'auto',
             left: 0,
             width: '100%',
             p: 1,
             backgroundColor: isMobile ? '#fff' : 'transparent',
             boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
           }}
         >

<Button
variant="contained"
sx={{
backgroundColor: '#4caf50',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#45a049',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>
         </Box>
          
            {/* </Paper> */}
          </Grid>


          {/* ---Mobile----- */}
          {!isMobile && (  
          <Grid item xs={12} md={5}>
            <DeviceEvaluation selectedModel={selectedModel} deviceAnswers={{ ...deviceAnswers, accessories: selectedAccessories }} formattedVariant={formattedVariant} answers={answers}/>
          </Grid>
          )}
        </Grid>
      )}


{showWarranty && (
  ((!userLogDetails || !userLogDetails.name || !userLogDetails.email || !userLogDetails.phoneNumber) && (!warrantyStatus || !showWarrantyPage)) ? (
    <CustomerLogin 
      selectedModel={selectedModel} 
      formattedVariant={formattedVariant} 
      currentValue={AccCurrVal} 
      warrantyStatus={warrantyStatus} 
      rowDetails={rowDetails} 
      answers={answers} 
      FixedPrice={FixedPrice} 
      deviceAnswers={{ ...deviceAnswers, accessories: selectedAccessories }} 
      ScreenselectedOptions={ScreenselectedOptions} 
      DefectsselectedOptions={DefectsselectedOptions} 
      functionalSelectedOptions={functionalSelectedOptions} 
      accSelectedOptions={selectedAccessories} 
      userLogDetails={userLogDetails}
      onFormData={handleFormData}

    />
  ) :
  (!warrantyStatus || !showWarrantyPage)&&
  <SellNow 
  selectedModel={selectedModel} 
  formattedVariant={formattedVariant} 
  currentValue={AccCurrVal} 
  warrantyStatus={warrantyStatus} 
  rowDetails={rowDetails} 
  answers={answers} 
  FixedPrice={FixedPrice} 
  deviceAnswers={{ ...deviceAnswers, accessories: selectedAccessories }} 
  ScreenselectedOptions={ScreenselectedOptions} 
  DefectsselectedOptions={DefectsselectedOptions} 
  functionalSelectedOptions={functionalSelectedOptions} 
  accSelectedOptions={selectedAccessories} 
  userLogDetails={userLogDetails}
  onFormData={handleFormData}
/>
  )}
  
  {
  showWarranty && 
  (warrantyStatus && showWarrantyPage)&&  
      <Warranty 
        selectedModel={selectedModel} 
        formattedVariant={formattedVariant} 
        currentValue={AccCurrVal} 
        warrantyStatus={warrantyStatus} 
        rowDetails={rowDetails} 
        answers={answers} 
        FixedPrice={FixedPrice} 
        deviceAnswers={{ ...deviceAnswers, accessories: selectedAccessories }} 
        ScreenselectedOptions={ScreenselectedOptions} 
        DefectsselectedOptions={DefectsselectedOptions} 
        functionalSelectedOptions={functionalSelectedOptions} 
        accSelectedOptions={selectedAccessories} 
        userLogDetails={userLogDetails}
        onFormData={handleFormData}
      />
   
  }

{!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}
  



      
    </>
  );
};

export default Accessories;
