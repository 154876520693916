import React, { useState,useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  CardMedia,
  CardActions,
  CardActionArea,
  useMediaQuery,
  IconButton,
  Drawer
  
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Pickup from './Pickup';
import LoginSignupPage from './CustomerLogin';
import safe from "../../assets/images/cos1.jpeg";
import cos2 from "../../assets/images/cos2.jpeg";
import payment_icon from "../../assets/images/payment_icon.jpeg";
import Device from '../screens/Device';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ'; 
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from 'react-redux';
import { handlecurrentValue } from '../../redux/WrapperSlice';

const SellNow = ({selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers,FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions,userLogDetails,onFormData,selectedWarranty}) => {
  const [currentPage, setCurrentPage] = useState('sellNow');
  const[DefaultPrice,setPrice] = useState(0);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();

  const handleContinue = () => {
    setCurrentPage('pickupPage');
  };
 const handleBack = () => {
    setCurrentPage('loginSignup');
  };

  const handleRecalculate = () => {
    // setCurrentPage("Device");
    navigate("/");
  }
  const isMobile = useMediaQuery('(max-width:600px)');


  useEffect(() => {
    if (currentValue < 0) {
      // //console.log(currentValue,"currentValue......")
      if (rowDetails["MaxValue"] < 15000) setPrice(1200);
      else if (rowDetails["MaxValue"] >= 15000 && rowDetails["MaxValue"] <= 35000) setPrice(1700);
      else if (rowDetails["MaxValue"] > 35000 && rowDetails["MaxValue"] <= 50000) setPrice(2200);
      else if (rowDetails["MaxValue"] > 50000) setPrice(3000);
    }
  }, [currentValue, rowDetails]);

  const finalValue = FixedPrice || (currentValue > 0 ? currentValue : DefaultPrice);

  useEffect(() => {
    dispatch(handlecurrentValue(finalValue));
  }, [dispatch, handlecurrentValue, finalValue]);


  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=AW-16712022597`; // Include Google Tag script
    document.head.appendChild(script);

    script.onload = () => {
      window.gtag = window.gtag || function () {
        (window.dataLayer = window.dataLayer || []).push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', 'AW-16712022597');
      window.gtag('event', 'conversion', { send_to: 'AW-16712022597/XLDVCLyutdUZEMX09KA-' });
    };

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);
  
  const handleFormData = (data) => {
    // //console.log(data,"Fundddd")
    // //console.log("Received form data func:", data);
    onFormData(data)
    
  };



  if (currentPage === 'pickupPage') {
    return (<Pickup selectedModel={selectedModel} formattedVariant={formattedVariant}  warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} userLogDetails={userLogDetails} onFormData={onFormData} selectedWarranty={selectedWarranty}/>);
  }

 

  if (currentPage === 'loginSignup') {
    return (<LoginSignupPage selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue > 0  ? currentValue : DefaultPrice} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} userLogDetails={userLogDetails} onFormData={onFormData} selectedWarranty={selectedWarranty}/>);
  }




  return (
    <Container sx={{
      // border: '1px solid #ddd', // No border on mobile
borderRadius: '8px',        // No border radius on mobile
padding: 2,                   // No padding on mobile
    }}>
      
    <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} md={6} sm={12} lg={6}>
          <Card sx={{ boxShadow: 4, borderRadius: "12px", overflow: "hidden" }}>
            <CardActionArea
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <CardMedia
                component="img"
                image={rowDetails[" Image URL"] || "/path/to/default/image.jpg"}
                alt="Device Image"
                sx={{
                  width: { xs: 120, sm: 150 },
                  height: { xs: 150, sm: 200 },
                  objectFit: "contain",
                  borderRadius: "8px",
                  backgroundColor: "#f8f8f8",
                  padding: "10px",
                }}
              />

              <Box sx={{ ml: { md: 2 }, textAlign: { xs: "center", md: "left" } }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                  }}
                >
                  {`${rowDetails["Brand name"]} ${rowDetails["Model"]} (${formattedVariant})` || "Phone Name"}
                </Typography>

                <Typography variant="body1" sx={{ mt: 1, color: "black",fontWeight:"600" }}>
                  Selling Price :
                </Typography>
                <Typography variant="h4" sx={{ mt: 1, color: "red",fontWeight:"bold" }}>
                ₹ {new Intl.NumberFormat("en-IN").format(finalValue)}
                </Typography>

                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    mt: 2,
                    textDecoration: "underline",
                    padding: 0,
                    minWidth: "auto",
                    fontSize:"medium",
                    // color:"#000",
                    fontWeight:"600",
                    
                    
                    textTransform:"capitalize"
                  }}
                  onClick={handleRecalculate}
                >
                  Recalculate
                </Button>

                {/* Feature Icons */}
        <Grid container sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <Grid item xs={4}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={payment_icon} style={{ maxWidth: "25px", marginBottom: "5px" }} alt="Payment Icon" />
      <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "10px", textAlign: "center" }}>
        Fast Payments
      </Typography>
    </Box>
  </Grid>

  {/* Divider */}
  {/* <Divider orientation="vertical" flexItem sx={{ height: "30px", backgroundColor: "#ccc", mx: 1 }} /> */}

  <Grid item xs={4}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={safe} style={{ maxWidth: "25px", marginBottom: "5px" }} alt="Safe Icon" />
      <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "10px", textAlign: "center" }}>
        Free Pickup
      </Typography>
    </Box>
  </Grid>

  {/* Divider */}
  {/* <Divider orientation="vertical" flexItem sx={{ height: "30px", backgroundColor: "#ccc", mx: 1 }} /> */}

  <Grid item xs={4}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={cos2} style={{ maxWidth: "25px", marginBottom: "5px" }} alt="Pickup Icon" />
      <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "10px", textAlign: "center" }}>
        100% Safe
      </Typography>
    </Box>
  </Grid>
</Grid>

{isMobile && (<FAQ  />)}


              </Box>
            </CardActionArea>
          </Card>
        </Grid>

        {!isMobile ? (
  <Grid item xs={12} sm={12} md={6} lg={6}   >
      {/* <Card sx={{ boxShadow: 4, borderRadius: '12px', overflow: 'hidden' }}> */}
      {/* <CardContent> */}
           
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Price Summary</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">
  Base Price: 
  {FixedPrice
                    ? `₹ ${new Intl.NumberFormat("en-IN").format(FixedPrice)}`
                    : `₹ ${new Intl.NumberFormat("en-IN").format(
                        currentValue > 0 ? currentValue : DefaultPrice
                      )}`}
</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Pickup Charges: Free <span style={{ textDecoration: 'line-through' }}>₹ 100</span></Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Processing Fee: ₹0</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Total Amount:   {FixedPrice
                    ? `₹ ${new Intl.NumberFormat("en-IN").format(FixedPrice)}`
                    : `₹ ${new Intl.NumberFormat("en-IN").format(
                        currentValue > 0 ? currentValue : DefaultPrice
                      )}`}
        </Typography>              {/* <Divider sx={{ my: 2 }} /> */}
             
          {/* </CardContent> */}
          <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: 'lightgreen',
                color: 'white',
                width: { xs: '100%', md: 'auto' }, // Full-width button on mobile
              }}              endIcon={<ArrowForwardIcon />}
              onClick={handleContinue}
            >
              Sell Now
            </Button>
          </CardActions>
        {/* </Card> */}
      </Grid>
        ) : (
        <Grid container sx={{ display: "flex", justifyContent: "space-between",alignItems:"center", p: 2,mt:5 }}>
      {/* Left Side: Total Price and View Breakup */}
      <Grid xs={4} sm={4} md={4}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {FixedPrice
                    ? `₹ ${new Intl.NumberFormat("en-IN").format(FixedPrice)}`
                    : `₹ ${new Intl.NumberFormat("en-IN").format(
                        currentValue > 0 ? currentValue : DefaultPrice
                      )}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "brown",
            cursor: "pointer",
            // textDecoration: "underline",
            fontWeight:"bold"
          }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          View Breakup
        </Typography>
      </Grid>
      <Grid xs={4} sm={4} md={4}>
      {/* Right Side: Sell Now Button */}
      <Button
      style={{background:"#0e7f71"}}
        variant="contained"
        onClick={handleContinue}
        sx={{
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#0e7f71',
          },
          color: "white",
          // width:""
          fontSize:"18px",
          whiteSpace:"noWrap"
        }}
        // endIcon={<ArrowForwardIcon />}
      >
        
        Sell Now
      </Button>
      </Grid>

      {/* Drawer for Price Breakdown */}
      <Drawer anchor="bottom" open={openDrawer} onClose={() =>  setOpenDrawer(!openDrawer)}>
        <Box sx={{ p: 3, width: "100%" }}>
  {/* Close Button */}
  <IconButton
    onClick={() => setOpenDrawer(!openDrawer)}
    sx={{ position: "absolute", right: 16, top: 16 }}
  >
    <CloseIcon />
  </IconButton>

  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
    Price Summary
  </Typography>
  <Divider sx={{ my: 2 }} />

  {/* Base Price */}
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="body1">Base Price:</Typography>
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
    {FixedPrice
                    ? `₹ ${new Intl.NumberFormat("en-IN").format(FixedPrice)}`
                    : `₹ ${new Intl.NumberFormat("en-IN").format(
                        currentValue > 0 ? currentValue : DefaultPrice
                      )}`}
    </Typography>
  </Box>
  <Divider sx={{ my: 2 }} />

  {/* Pickup Charges */}
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="body1">Pickup Charges:</Typography>
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
    <span style={{color:"#0e7f71"}}>Free</span>  <span style={{ textDecoration: "line-through" }}>  ₹ 100</span> 
    </Typography>
  </Box>
  <Divider sx={{ my: 2 }} />

  {/* Processing Fee */}
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="body1">Processing Fee:</Typography>
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
      <span style={{ textDecoration: "line-through" }}>₹ 100</span> ₹ 0
    </Typography>
  </Box>
  <Divider sx={{ my: 2 }} />

  {/* Final Price */}
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
  {FixedPrice
                    ? `₹ ${new Intl.NumberFormat("en-IN").format(FixedPrice)}`
                    : `₹ ${new Intl.NumberFormat("en-IN").format(
                        currentValue > 0 ? currentValue : DefaultPrice
                      )}`}
    </Typography>

      <Button
      style={{background:"#0e7f71"}}
       onClick={handleContinue}
        variant="contained"
        sx={{
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#0e7f71',
          },
          color: "white",
          // width:""
          fontSize:"18px",
          whiteSpace:"noWrap"
        }}
        // endIcon={<ArrowForwardIcon />}
      >
        Sell Now
      </Button>
  </Box>
</Box>

      </Drawer>
    </Grid>
        )}

    
    </Grid>
    {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}


  </Container>
  );
};

export default SellNow;
